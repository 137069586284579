// Auto-generated RPC interface from /opt/whdata/installedmodules/coopertires_sites.20240405T113501.601Z/js/cardbservice.rpc.json
var RPCClient = require("@mod-system/js/wh/rpc").default;
var request = exports.rpcclient = new RPCClient("coopertires:rpc");
exports.rpcResolve = function (promise, result) { request._handleLegacyRPCResolve(promise, result) };
exports.invoke = function () { return request.invoke.apply(request,Array.prototype.slice.call(arguments)); }

Object.defineProperty(module.exports, "HTTP_ERROR", { get: function () { return JSONRPC.HTTP_ERROR; }});
Object.defineProperty(module.exports, "JSON_ERROR", { get: function () { return JSONRPC.JSON_ERROR; }});
Object.defineProperty(module.exports, "PROTOCOL_ERROR", { get: function () { return JSONRPC.PROTOCOL_ERROR; }});
Object.defineProperty(module.exports, "RPC_ERROR", { get: function () { return JSONRPC.RPC_ERROR; }});
Object.defineProperty(module.exports, "OFFLINE_ERROR", { get: function () { return JSONRPC.OFFLINE_ERROR; }});
Object.defineProperty(module.exports, "TIMEOUT_ERROR", { get: function () { return JSONRPC.TIMEOUT_ERROR; }});
Object.defineProperty(module.exports, "SERVER_ERROR", { get: function () { return JSONRPC.SERVER_ERROR; }});

// Adding dependency: '/opt/whdata/installedmodules/coopertires.20240222T141438.957Z/lib/services/rpc.whlib'

exports.findMasters = exports.FindMasters = /*RECORD ARRAY*/function (/*STRING*/ channel, /*STRING*/ language, /*RECORD*/ query, /*RECORD*/ options)
{
return request.invoke.apply(request,["FindMasters"].concat(Array.prototype.slice.call(arguments)));
}

exports.getTireSizeOptions = exports.GetTireSizeOptions = /*RECORD*/function (/*STRING*/ channel, /*RECORD*/ query)
{
return request.invoke.apply(request,["GetTireSizeOptions"].concat(Array.prototype.slice.call(arguments)));
}

exports.getMotorsportTireSizeOptions = exports.GetMotorsportTireSizeOptions = /*RECORD*/function (/*STRING*/ channel, /*RECORD*/ query)
{
return request.invoke.apply(request,["GetMotorsportTireSizeOptions"].concat(Array.prototype.slice.call(arguments)));
}

exports.getCarSizeOptions = exports.GetCarSizeOptions = /*RECORD*/function (/*STRING*/ channel, /*STRING*/ car, /*RECORD*/ query)
{
return request.invoke.apply(request,["GetCarSizeOptions"].concat(Array.prototype.slice.call(arguments)));
}

exports.getSearchDatabase = exports.GetSearchDatabase = /*RECORD*/function (/*STRING*/ cartype)
{
return request.invoke.apply(request,["GetSearchDatabase"].concat(Array.prototype.slice.call(arguments)));
}

exports.getVariants = exports.GetVariants = /*RECORD ARRAY*/function (/*STRING*/ variantkey, /*STRING*/ cartype, /*RECORD*/ options)
{
return request.invoke.apply(request,["GetVariants"].concat(Array.prototype.slice.call(arguments)));
}
